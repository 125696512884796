import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Tokyo3 = () => (
  <Layout>
    <GatsbySeo
      title="Universidad de Tokyo"
      titleTemplate="%s | Eduardo Ceballos"
      description=""
      canonical="https://eduardoceballos.com/tokyo"
      openGraph={{
        url: `https://eduardoceballos.com/tokyo`,
        title: `Universidad de Tokyo`,
        description: ``,
        images: [
          {
            url: `https://eduardoceballos.com/seo/tokyo.png`,
            alt: ``,
          },
        ],
        site_name: "Eduardo Ceballos",
      }}
      twitter={{
        handle: "@PoetadeSalta",
        site: "@PoetadeSalta",
        cardType: "summary_large_image",
      }}
    />
    <h1>3. Equipo de producción</h1>
    <h6>
      <a href="https://spanish.ecc.u-tokyo.ac.jp/aries/amorvida/index.html">
        Fuente original.
      </a>
    </h6>
    <div className="tokyo-menu-flex">
      <Link to="/tokyo">0. Presentación</Link>
      <Link to="/tokyo/1">1. Entrevista</Link>
      <Link to="/tokyo/2">2. Recitación</Link>
      <Link to="/tokyo/3">3. Equipo de producción</Link>
      <Link to="/tokyo/4">4. Agradecimientos</Link>
    </div>
    <h6>
      Poeta: Eduardo Ceballos <br />
      Presentación: Viviana Cristina Ceballos <br />
      Cámara y edición: Hiroto Ueda <br />
      Coordinación: Francisco Fernández
    </h6>
  </Layout>
)

export default Tokyo3
